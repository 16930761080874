import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Site from './components/Site';
import Alert from './components/Alert';
import { useState } from 'react';

function App() {
  const [alert, setAlert] = useState(null)
  const showAlert = (massage, type) =>{
          setAlert({
            msg: massage,
            type:type
          })
        }
  return (
   <>
   <Header></Header>
      <Navbar nav1='Home' nav2='Enrollment'></Navbar>
      <Alert alert={alert} ></Alert>
      <Site></Site>
      <Footer></Footer>
   </>
  );
}

export default App;
