import React from 'react'

export default function Footer() {
  return (
    <div className="footer">
<div>Natraj Pvt Ltd © 2022 creativeLabs.</div>
<div className="ms-auto">Powered by&nbsp;<a href="https://reactjs.org/">Asp.Net and React.js</a></div>
</div>
  )
}
